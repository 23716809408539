import { MenuList } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import MenuItem from "@material-ui/core/MenuItem";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import * as React from "react";

import { useRequiredAuthContext } from "~/components/auth/AuthWrapper";
import styled from "~/components/core/styled";
import { UmbrellaAccount } from "~/declarations/models/UmbrellaAccount";
import { trackEvent } from "~/utils/segment";

const UMBRELLA_DISPLAY_NAME_STUB = "School Name/Organization";

const AccountUserInstitutions: React.FC = (): JSX.Element => {
  const { user } = useRequiredAuthContext();
  const { selectedUmbrella, onSelectUmbrella } = useRequiredAuthContext();
  const [
    selectAnchorEl,
    setSelectAnchorEl
  ] = React.useState<HTMLAnchorElement | null>(null);
  const accounts = user?.umbrellaAccounts;

  const handleClickSelect = (
    event: React.MouseEvent<HTMLAnchorElement>
  ): void => {
    event.stopPropagation();
    setSelectAnchorEl(event.currentTarget);
  };

  const handleCloseSelect = (): void => {
    setSelectAnchorEl(null);
  };

  const handleBackdropClickSelect = (
    event: React.MouseEvent<HTMLDivElement>
  ): void => {
    event.stopPropagation();
  };

  const handleClickSelectItem = (umbrellaAccount: UmbrellaAccount) => (
    event: React.MouseEvent<HTMLLIElement>
  ): void => {
    event.stopPropagation();
    onSelectUmbrella && onSelectUmbrella(umbrellaAccount);
    setSelectAnchorEl(null);
    trackEvent("change_umbrella_account", {
      umbrella: umbrellaAccount.displayName ?? selectedUmbrella.umbrellaAccName
    });
  };

  if (!accounts) {
    return <Institution>{UMBRELLA_DISPLAY_NAME_STUB}</Institution>;
  }

  if (accounts?.length === 1) {
    return (
      <Institution>
        {selectedUmbrella.displayName ?? selectedUmbrella.umbrellaAccName}
      </Institution>
    );
  }

  const selectOpen = Boolean(selectAnchorEl);

  return (
    <>
      {selectedUmbrella.displayName ?? selectedUmbrella.umbrellaAccName ? (
        <Link onClick={handleClickSelect}>
          <InstitutionName>
            {selectedUmbrella.displayName ?? selectedUmbrella.umbrellaAccName}
          </InstitutionName>
        </Link>
      ) : null}
      <Popover
        anchorEl={selectAnchorEl}
        open={selectOpen}
        onClose={handleCloseSelect}
        onBackdropClick={handleBackdropClickSelect}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right"
        }}
      >
        <MenuList>
          {accounts?.map(
            (umbrellaAccount: UmbrellaAccount): JSX.Element => (
              <MenuItem
                key={umbrellaAccount.umbrellaAccName}
                onClick={handleClickSelectItem(umbrellaAccount)}
                selected={
                  umbrellaAccount.umbrellaAccName ===
                  selectedUmbrella.umbrellaAccName
                }
              >
                {umbrellaAccount.displayName ?? umbrellaAccount.umbrellaAccName}
              </MenuItem>
            )
          )}
        </MenuList>
      </Popover>
    </>
  );
};

export default AccountUserInstitutions;

const Institution = styled(Typography)`
  color: ${({ theme }): string => theme.palette.primary.main};
`;

const InstitutionName = styled(Typography)`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  overflow: hidden;
`;
